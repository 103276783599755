

function Hero_section() {
    return ( 
        <>
        <div class="carousel-header">
            <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
                {/* <ol class="carousel-indicators">
                    <li data-bs-target="#carouselId" data-bs-slide-to="0" class="active"></li>
                    <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
                </ol> */}
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <img src="banner1.jpg" class="img-fluid" alt="Image"/>
                        <div class="carousel-caption">
                            <div class="text-center p-4"style={{maxWidth:900}}>
                                <h4 class="text-white text-uppercase fw-bold mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.1s"></h4>
                                <h1 class="display-1 text-capitalize text-white mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.3s"></h1>
                                <h1 class="text-white mb-4 mb-md-5 display-4 wow fadeInUp" data-wow-delay="0.5s">MORE SPACE TO LIVE YOUR LIFE HAPPLY.
                                </h1>
                                <a class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5 wow fadeInUp" data-wow-delay="0.7s" href="#">Book Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="banner2.jpg" class="img-fluid" alt="Image"/>
                        <div class="carousel-caption">
                            <div class="text-center p-4" style={{maxWidth:900}}>
                                <h5 class="text-white text-uppercase fw-bold mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.1s"></h5>
                                <h1 class="display-1 text-capitalize text-white mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.3s"></h1>
                                <h1 class="text-white mb-4 mb-md-5 display-4 wow fadeInUp" data-wow-delay="0.5s">MORE SPACE TO LIVE YOUR LIFE HAPPLY.
                                </h1>
                                <a class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5 wow fadeInUp" data-wow-delay="0.7s" href="#">Book Now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon  wow fadeInLeft" data-wow-delay="0.2s" aria-hidden="false"></span>
                    <span class="visually-hidden-focusable">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                    <span class="carousel-control-next-icon  wow fadeInRight" data-wow-delay="0.2s" aria-hidden="false"></span>
                    <span class="visually-hidden-focusable">Next</span>
                </button>
            </div>
        </div>
        </>
     );
}

export default Hero_section;