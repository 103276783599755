function Video_section() {
    return ( 
        <>
         <section class="video-area">
      <div class="container">
        <div class="row justify-content-center align-items-center flex-column text-center">
          <a id="play-home-video" class="video-play-button" href="https://youtu.be/-h_pW2izoj8?si=sQrFx3jEt8wdJS8z">
            <span></span>
          </a>
          <h3>Ocean Breeze Haven</h3>
          <p>View four has said does men saw find dear shy talent</p>
        </div>
      </div>  
    </section>
        </>
     );
}

export default Video_section;