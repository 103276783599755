import React, { useState } from 'react';

function Properties() {
    // State to track the active tab
    const [activeTab, setActiveTab] = useState("table");

    // Function to handle tab switching
    const handleTabClick = (tab,event) => {
        setActiveTab(tab);
        event.preventDefault();
    };

    return (
        <>
            <div id="" className="abc mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center">
                                <h2 className="display-4">Hotel Facilities</h2>
                            </div>
                        </div>
                    </div>

                    <div id="tabs">
                        <nav className="tabs-nav">
                            <a href="#table" className={activeTab === "table" ? "active" : ""} onClick={(event) => handleTabClick("table",event)}>
                                <i className="flaticon-restaurant icon"></i>
                                <span className='custom_span'>Restaurant</span>
                            </a>
                            <a href="#table2" className={activeTab === "table2" ? "active" : ""} onClick={(event) => handleTabClick("table2",event)}>
                                <i className="flaticon-cup icon"></i>
                                <span className='custom_span'>Bar</span>
                            </a>
                            <a href="#tab3" className={activeTab === "tab3" ? "active" : ""} onClick={(event) => handleTabClick("tab3",event)}>
                                <i className="flaticon-car icon"></i>
                                <span className='custom_span'>Pick-up</span>
                            </a>
                            <a href="#tab4" className={activeTab === "tab4" ? "active" : ""} onClick={(event) => handleTabClick("tab4",event)}>
                                <i className="flaticon-swimming icon"></i>
                                <span className='custom_span'>Swimming Pool</span>
                            </a>
                            <a href="#tab5" className={activeTab === "tab5" ? "active" : ""} onClick={(event) => handleTabClick("tab5",event)}>
                                <i className="flaticon-massage icon"></i>
                                <span className='custom_span'>Spa</span>
                            </a>
                            <a href="#tab6" className={activeTab === "tab6" ? "active" : ""} onClick={(event) => handleTabClick("tab6",event)}>
                                <i className="flaticon-bicycle icon"></i>
                                <span className='custom_span'>Gym</span>
                            </a>
                        </nav>

                        <div className="tab-content-container">
                            <div className={`tab-content ${activeTab === "table" ? "active show" : ""}`} id="table">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src="tab_img_11.jpg" className="img-responsive" alt="Restaurant" />
                                        </div>
                                        <div className="col-md-6">
                                            <span className="super-heading-sm">World Class</span>
                                            <h3 className="heading">Restaurant</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
                                            <p className="service-hour">
                                                <span>Service Hours</span>
                                                <strong>7:30 AM - 8:00 PM</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`tab-content ${activeTab === "table2" ? "active show" : ""}`} id="table2">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src="tab_img_22.jpg" className="img-responsive" alt="Bar" />
                                        </div>
                                        <div className="col-md-6">
                                            <span className="super-heading-sm">World Class</span>
                                            <h3 className="heading">Bar</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
                                            <p className="service-hour">
                                                <span>Service Hours</span>
                                                <strong>7:30 AM - 8:00 PM</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Repeat similar structure for other tabs */}
                            <div className={`tab-content ${activeTab === "tab3" ? "active show" : ""}`} id="tab3">
								<div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src="tab_img_33.jpg" className="img-responsive" alt="Bar" />
                                        </div>
                                        <div className="col-md-6">
                                            <span className="super-heading-sm">World Class</span>
                                            <h3 className="heading">Pick-up</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
                                            <p className="service-hour">
                                                <span>Service Hours</span>
                                                <strong>7:30 AM - 8:00 PM</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-content ${activeTab === "tab4" ? "active show" : ""}`} id="tab4">
								<div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src="tab_img_44.jpg" className="img-responsive" alt="" />
                                        </div>
                                        <div className="col-md-6">
                                            <span className="super-heading-sm">World Class</span>
                                            <h3 className="heading">Swimming Pool</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
                                            <p className="service-hour">
                                                <span>Service Hours</span>
                                                <strong>7:30 AM - 8:00 PM</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-content ${activeTab === "tab5" ? "active show" : ""}`} id="tab5">
								<div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src="tab_img_55.jpg" className="img-responsive " alt="Bar" />
                                        </div>
                                        <div className="col-md-6">
                                            <span className="super-heading-sm">World Class</span>
                                            <h3 className="heading">Spa</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
                                            <p className="service-hour">
                                                <span>Service Hours</span>
                                                <strong>7:30 AM - 8:00 PM</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-content ${activeTab === "tab6" ? "active show" : ""}`} id="tab6">
								<div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src="tab_img_6.jpg" className="img-responsive" alt="Bar" />
                                        </div>
                                        <div className="col-md-6">
                                            <span className="super-heading-sm">World Class</span>
                                            <h3 className="heading">Gym</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
                                            <p className="service-hour">
                                                <span>Service Hours</span>
                                                <strong>7:30 AM - 8:00 PM</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Properties;
