import React from "react";
import Hero_section from "./Hero_section";
import Form_section from "./Form_section";
import About_section from "./About_section";
import Explore_section from "./Explore_section";
import Video_section from "./Video_section";
import Counter_section from "./Counter_section";
import Service_section from "./Service_section";
import Review_section from "./Review_section";
import Telphone_section from "./Telphone_section";


function Home (){

    return(
        <>
        
        <Hero_section/>
        <Form_section/>
        <Counter_section/>
        <About_section/>
        <Explore_section/>
        <Video_section/>
        <Service_section/>
        <Review_section/>
        <Telphone_section/>
        
        
        </>
    );
}
export default Home;