import 'animate.css';


function About_section() {
    return ( 
        <section className="welcome">
            <h1 className='text-center display-4 '>About Us</h1>
        <div className="container mt-5">
          <div className="row align-items-center">
            <div className="col-lg-5 mb-4 mb-lg-0">
              <div className="row no-gutters welcome-images">
                <div className="col-sm-6">
                  <div className="card hover-animate">
                    <img className="img-fluid" src="blog3.jpg" alt="Card image cap"/>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card hover-animate">
                    <img className="img-fluid" src="blog2.jpg" alt="Card image cap"/>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="card hover-animate">
                    <img className="img-fluid" src="blog1.jpg" alt="Card image cap"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="welcome-content">
                <h2 className="mb-4"><span className="d-block ">Welcome</span> to our residence</h2>
                <p>Beginning blessed second a creepeth. Darkness wherein fish years good air whose after seed appear midst evenin, appear void give third bearing divide one so blessed moved firmament gathered </p>
                <p>Beginning blessed second a creepeth. Darkness wherein fish years good air whose after seed appear midst evenin, appear void give third bearing divide one so blessed</p>
                <a className="button button--active home-banner-btn mt-4" href="#">Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default About_section;

