import img1 from "./images/person_1.jpg"
import img2 from "./images/person_2.jpg"
import img3 from "./images/person_3.jpg"
import img4 from "./images/person_4.jpg"
import img5 from "./images/person_5.jpg"
import img6 from "./images/person_6.jpg"



function Review_section() {
  return (
    <div class="container " id="section-team">

      <div class="row justify-content-center text-center mt-5">
        <div class="col-md-7 mb-3">
          <h2 class="heading" data-aos="fade-up">Our Guest Love Us</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
          <div class="block-2">
            <div class="flipper">
              <div class="front" style={{ backgroundImage: `url(${img1})`, backgroundRepeat: 'no-repeat' }}>
                <div class="box">
                  <h2>Will Smith</h2>
                  <p>President</p>
                </div>
              </div>
              <div class="back">
                {/* <!-- back content  */}
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <div class="author d-flex">
                  <div class="image mr-3 align-self-center">
                    <img src="person_1.jpg" alt="" />
                  </div>
                  <div class="name align-self-center">Will Smith <span class="position">President</span></div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .flip-container --> */}
        </div>

        <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
          <div class="block-2">
            {/* <!-- .hover --> */}
            <div class="flipper">
              <div class="front" style={{ backgroundImage: `url(${img2})`, backgroundRepeat: 'no-repeat' }}>
                <div class="box">
                  <h2>Claire Williams</h2>
                  <p>Business Manager</p>
                </div>
              </div>
              <div class="back">
                {/* <!-- back content --> */}
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <div class="author d-flex">
                  <div class="image mr-3 align-self-center">
                    <img src="person_2.jpg" alt="" />
                  </div>
                  <div class="name align-self-center">Claire Williams <span class="position">Business Manager</span></div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .flip-container --> */}
        </div>

        <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
          <div class="block-2">
            <div class="flipper">
              <div class="front" style={{ backgroundImage: `url(${img3})`, backgroundRepeat: 'no-repeat' }}>
                <div class="box">
                  <h2>Jane Johnson</h2>
                  <p>Marketing Director</p>
                </div>
              </div>
              <div class="back">
                {/* <!-- back content --> */}
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <div class="author d-flex">
                  <div class="image mr-3 align-self-center">
                    <img src="person_3.jpg" alt="" />
                  </div>
                  <div class="name align-self-center">Jane Johnson <span class="position">Marketing Director</span></div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .flip-container --> */}
        </div>


        <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
          <div class="block-2">
            <div class="flipper">
              <div class="front" style={{ backgroundImage: `url(${img4})`, backgroundRepeat: 'no-repeat' }}>
                <div class="box">
                  <h2>Will Smith</h2>
                  <p>President</p>
                </div>
              </div>
              <div class="back">
                {/* <!-- back content --> */}
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <div class="author d-flex">
                  <div class="image mr-3 align-self-center">
                    <img src="person_4.jpg" alt="" />
                  </div>
                  <div class="name align-self-center">Will Smith <span class="position">President</span></div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .flip-container --> */}
        </div>

        <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
          <div class="block-2">
            {/* <!-- .hover --> */}
            <div class="flipper">
              <div class="front" style={{ backgroundImage: `url(${img5})`, backgroundRepeat: 'no-repeat' }}>
                <div class="box">
                  <h2>Claire Williams</h2>
                  <p>Business Manager</p>
                </div>
              </div>
              <div class="back">
                {/* <!-- back content --> */}
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <div class="author d-flex">
                  <div class="image mr-3 align-self-center">
                    <img src="person_5.jpg" alt="" />
                  </div>
                  <div class="name align-self-center">Claire Williams <span class="position">Business Manager</span></div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .flip-container --> */}
        </div>

        <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
          <div class="block-2">
            <div class="flipper">
              <div class="front" style={{ backgroundImage: `url(${img6})`, backgroundRepeat: 'no-repeat' }}>
                <div class="box">
                  <h2>Jane Johnson</h2>
                  <p>Marketing Director</p>
                </div>
              </div>
              <div class="back">
                {/* <!-- back content --> */}
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <div class="author d-flex">
                  <div class="image mr-3 align-self-center">
                    <img src="person_6.jpg" alt="" />
                  </div>
                  <div class="name align-self-center">Jane Johnson <span class="position">Marketing Director</span></div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .flip-container --> */}
        </div>

      </div>
    </div>
  );
}

export default Review_section;