import About from './About';
import Footer_section from './Footer_section';
import Header_section from './Header_section';
import Home from './Home';
import { BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Properties from './Properties';
import Contact from './Contact';
import Gellery from './Gellery';
import Blog from './Blog';

function App() {
  return (
   <>
   <Router>
    <Header_section/>
    <Routes>
      <Route  path='/' element={ <Home/> } />
      <Route path='/About' element={<About/>}/>
      <Route path='/Properties' element={<Properties/>}/>
      <Route path='/gellery' element={<Gellery/>}/>
      <Route path='/blog' element={<Blog/> }/>
      <Route path='/contact' element={<Contact/> }/>
    </Routes>
    <Footer_section/>
   </Router>
   </>
  );
}

export default App;
