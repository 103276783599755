import React from "react";

function Blog() {
    return ( 
        <>
         <section class="blog_section ">
    <div class="container">
      <div class="heading_container">
        <h2 className="display-4 mt-5">
          Latest Blog
        </h2>
      </div>
      <div class="row">
        <div class="col-lg-6 ">
          <div class="box">
            <div class="img-box">
              <img src="gellery/images/s2.jpg" alt=""/>
            </div>
            <div class="detail-box">
              <h5>
                Velit tempora molestias quae
              </h5>
              <p>
                Omnis itaque ducimus excepturi dignissimos voluptatibus sequi nisi ut ullam, perspiciatis doloribus! Cum itaque sint quibusdam aut vel. A esse labore.
              </p>
              <a href="">
                Read More
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 ">
          <div class="box">
            <div class="img-box">
              <img src="gellery/images/s1.jpg" alt=""/>
            </div>
            <div class="detail-box">
              <h5>
                Repudiandae voluptatum quaerat
              </h5>
              <p>
                Totam non minus suscipit, exercitationem deserunt doloribus provident dolor quos nulla impedit, perspiciatis excepturi eius hic vero harum deleniti.
              </p>
              <a href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
        </>
     );
}

export default Blog;