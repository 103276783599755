import { useEffect } from "react";

function Counter_section() {
    useEffect(() => {
        const counters = document.querySelectorAll('.js-counter');
        counters.forEach(counter => {
            const updateCount = () => {
                const target = +counter.getAttribute('data-to');
                const speed = +counter.getAttribute('data-speed');
                const count = +counter.innerText;
                const increment = target / (speed / 100);

                if (count < target) {
                    counter.innerText = Math.ceil(count + increment);
                    setTimeout(updateCount, 50);
                } else {
                    counter.innerText = target;
                }
            };
            updateCount();
        });
    }, []);

    return (
        <>
            <div id="fh5co-counter-section" className="fh5co-counters">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 text-center">
                            <span className="fh5co-counter js-counter" data-from="0" data-to="20356" data-speed="5000"></span>
                            <span className="fh5co-counter-label">User Access</span>
                        </div>
                        <div className="col-md-3 text-center">
                            <span className="fh5co-counter js-counter" data-from="0" data-to="15501" data-speed="5000"></span>
                            <span className="fh5co-counter-label">Hotels</span>
                        </div>
                        <div className="col-md-3 text-center">
                            <span className="fh5co-counter js-counter" data-from="0" data-to="8200" data-speed="5000"></span>
                            <span className="fh5co-counter-label">Transactions</span>
                        </div>
                        <div className="col-md-3 text-center">
                            <span className="fh5co-counter js-counter" data-from="0" data-to="8763" data-speed="5000"></span>
                            <span className="fh5co-counter-label">Rating &amp; Review</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Counter_section;
