import React from "react";
import { useFormik } from "formik";
import { signup } from "./Signup";




    const initialValues = {
        Name : "",
        Email : "",
        Number :"",
        Message : "",
    }


const Telphone_section = () => {
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
      useFormik({
        initialValues,
        validationSchema: signup,
        onSubmit: (values, action) => {
          console.log(
            "🚀  file: Registration.jsx  line 11  Registration  values",
            values
          );
          action.resetForm();
        },
      });
    console.log(
      "🚀  file: Registration.jsx  line 25  Registration  errors",
      errors
    );

    
    return (
        <>
            <div class="contact mt-0">
                <div class="container">
                    <div class="row">

                        <h1 className="text-center display-4">Contact Us</h1>
                        {/* <div class="col-md-12">
                            <div class="titlepage">
                                <h2>Contact Us</h2>
                            </div>
                        </div> */}
                    </div>
                    <div class="row mt-5">
                        <div class="col-md-6">
                            <form id="request" class="main_form" onSubmit={handleSubmit}>
                                <div class="row">
                                    <div class="col-md-12 ">
                                        <input class="contactus" id="Name" autoComplete="off" placeholder="Name" type="text" name="Name" value={values.Name} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.Name && touched.Name ? (
                                        <p className="form-error">{errors.Name}</p>
                                        ) : null}
                                    </div>
                                    <div class="col-md-12">
                                        <input class="contactus" id="Email" placeholder="Email" type="email" name="Email" value={values.Email} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.Email && touched.Email ? (
                                        <p className="form-error">{errors.Email}</p>
                                        ) : null}
                                    </div>
                                    <div class="col-md-12">
                                        <input class="contactus" id="Number" placeholder="Phone Number" type="number" name="Number" value={values.Number} onChange={handleChange} onBlur={handleBlur}/>
                                        {errors.Number && touched.Number ? (
                                        <p className="form-error">{errors.Number}</p>
                                        ) : null}
                                    </div>
                                    <div class="col-md-12">
                                        <textarea class="textarea" id="Message" placeholder="Message" type="message" name="Message" value={values.Message} onChange={handleChange} onBlur={handleBlur}>Message</textarea>
                                        {errors.Message && touched.Message ? (
                                        <p className="form-error">{errors.Message}</p>
                                        ) : null}
                                    </div>
                                    <div class="col-md-12">
                                        <button class="send_btn">Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-6">
                            <div class="map_main">
                                <div class="map-responsive">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3943.0760108705063!2d76.6751125740468!3d8.778919392855409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05e500722367a3%3A0xd6c869d04ece38b7!2sOcean%20Breeze%20Beach%20Resort!5e0!3m2!1sen!2sin!4v1729497793401!5m2!1sen!2sin" width="600" height="450"style={{border:0,width:"100%"}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Telphone_section;