import React from "react";
import { Link } from "react-router-dom";

function Header_section() {
    return ( 
        <>
        <div class="container-fluid nav-bar p-0">
            <nav class="navbar navbar-expand-lg navbar-light bg-dark px-4 px-lg-5 py-3 py-lg-0">
                <a href="" class="navbar-brand p-0">
                    <h1 class="display-5 text-secondary m-0"><img src="logo.png" class="img-fluid" alt=""/></h1>
                    
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span class="fa fa-bars"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <div class="navbar-nav ms-auto py-0">
                        <Link to="/" class="nav-item nav-link ">Home</Link>
                        <Link to="/About" class="nav-item nav-link">About</Link>
                        <Link to="/Properties" class="nav-item nav-link">Properties</Link>
                        <Link to="/gellery" class="nav-item nav-link">Gellery</Link>
                        <Link to="/blog" class="nav-item nav-link">Blog</Link>
                        <Link to="/Contact" class="nav-item nav-link">Contact</Link>
                    </div>
                  
                </div>
            </nav>
        </div>
        </>
     );
}

export default Header_section;